.student-question-card-container {
    display: flex;
    //width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 0px!important;
    background: url('../../assets/img/shapes.png') no-repeat;
    background-size: cover;
    background-color: #18698d;
    .student-question-card-header {
        display: flex;
        flex-direction: column;
        .question-progress {
            margin-bottom: 25px;
            display: flex;
            justify-content: center;
        }
        .question-number {
            display: flex;
            justify-content: space-around;
            margin-bottom: 15px;
            .question-index {
                font-weight: bold;
                font-size: x-large;
                cursor: pointer;
                color: $gray;
            }
            .change-question-index {
                .change-question-icon {
                    cursor: pointer;
                    color: $gray
                }
                .disabled-icon {
                    color: $darke-gray;
                }
            }
        }
        .question-title {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
            font-size: xx-large;
            min-height:30px;
            align-items: center;
            font-family: 'Recursive', sans-serif !important;
            color:$gray;
        }
        .question-description {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
            font-size: x-large;
            min-height:50px;
            align-items: center;
        }
        .question-media {
            display: flex;
            justify-content: center;
            margin-bottom: 15px;
        }
        .question-audio-player {
            display: flex;
            justify-content: center;
            margin-bottom: 15px;
            >div {
                height: 40px!important;
            }
        }
    }

    .question-answers {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .question-answer {
            display: flex;
            flex:1;
            justify-content: space-between;
            margin-bottom: 10px;
            border: 2px solid $gray;
            padding: 10px;
            border-radius: 7px;
            font-weight: bold;
            align-items: center;
            min-width: 50%;
            .checked-answer-icon {
                color: white;
                cursor: pointer;
            }
            .unchecked-answer-icon {
                color: $gray4;
            }
            &:hover {
                //background-color: $principal_blue;
                //color: white;
                .check-answer {
                    display: flex;
                    align-items: center;
                }
            }
        }
        .selected-answer {
            background:linear-gradient(135deg,$green 0%,#00d5a1 20%) !important;
            color: white;
        }
        .submit-answer {
            margin: 0px 0px;
            background: url("../../assets/img/shapes.svg") ,
            linear-gradient(135deg, $blue 0%, $principal_blue 100%);
            border-radius: 20px;
            display: flex;
            justify-content: center;
            font-weight: bold;
            cursor: pointer;
            font-size: medium;
            padding: 13px 30px;
            width: 8%;

            .submit-text {
                color: white;
                display: flex;
                justify-content: center
            }
            &:hover {
                .submit-text {
                    color: $principal_blue;
                }
                background: white !important;
                border: 1px solid $principal_blue;
                box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
            }
        }
    }

}