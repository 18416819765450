

$gray:#F0F2F5;
$gray2: #F4F6F6;
$gray3 : #B4BDBE;
$darke-gray:#5a6e73;
$gray4:#a2a2a7;
$blue:#2BA7DF;
$principal_blue:#40BCF9;
$darke-blue:#18698D;
$green:#29AB87;
$gray-background:#F4F8FF;
$shadow:rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
$red: #dd4747;
$darke-red: #bd4848;