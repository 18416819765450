@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');

.student-forms {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .student-form-header {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    //border: 1px solid $darke-gray ;
    border-radius: 8px;
    background: url("../../assets/img/shapes.svg"), linear-gradient(135deg, #2BA7DF 0%, #40BCF9 100%);
    color: white;
    font-weight: bold;
    font-size: 20px;
    border:1px solid $gray2;
  }
  .list-form{
    width: 100%;
    background-color: $gray-background;
    margin-top: 10px;
  .student-form {
    max-width: 1140px;
    margin: 10px auto;
    background-color: $gray-background;
    border-radius: 5px;

    .student-form-input {
      display: flex;
      justify-content: space-between;
      border: 1px solid #ddd;
      padding: 10px;
      border-radius: 7px;
      font-weight: bold;
      align-items: center;
      min-width: 50%;
      margin: 20px 40px;
      background-color: white;

     

      .student-form-icon {
        display: flex;
        padding: 5px;
        margin-right: 15px;
        cursor: pointer;
        background: #d9e2e3;
        color:#30484e;
        &:hover {
          background:#b3c5c7;
        }
        border-radius: 5px
      }
    }
  }
  }
} 
.question-head-true {
  display: flex;
  flex:1;
  justify-content: space-between;
  margin-bottom: 10px;
  border: 2px solid #c4bfc2;
  padding: 10px;
  border-radius: 7px;
  font-weight: bold;
  align-items: center;
  min-width: 50%;
  background: rgba(201, 241, 203, 0.6)
}
.question-head-false {
  display: flex;
  flex:1;
  justify-content: space-between;
  margin-bottom: 10px;
  border: 2px solid #c4bfc2;
  padding: 10px;
  border-radius: 7px;
  font-weight: bold;
  align-items: center;
  min-width: 50%;
  background: rgba(243, 184, 184, 0.6)
 
}
.student-result-true {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 7px;
  font-weight: bold;
  align-items: center;
  min-width: 50%;
  margin: 20px 40px;
  background: rgba(76, 175, 80, 0.6)
 

}
.student-result-false {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 7px;
  font-weight: bold;
  align-items: center;
  min-width: 50%;
  margin: 20px 40px;
  background: rgba(165, 154, 154, 0.6)
 

}
.form-result-icon {
  display: flex;
  padding: 5px;
  margin-right: 15px;
  cursor: pointer;
  background: #d9e2e3;
  color:#30484e;
  &:hover {
    background:#b3c5c7;
  }
  border-radius: 5px
}

.backImage{
  border-radius: 0.5 !important;
  background-image: url("../../assets/img/shapes.svg") !important;
  background-color: rgb(62, 124, 179);
  
}

.btnTitle{
  font-family: 'Luckiest Guy', cursive;
  font-size: 30px;
  justify-items: center;
  text-align: center;
}