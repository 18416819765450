@import "../Questions/question";
@import "../../../Components/Profile/profile";
.form-card {
  display: flex !important;
  min-height: 900px;
  .form-container {
    width: 100%;

    .logout-forms {
      display: flex;
      justify-content: flex-end;
      margin: 10px;
      cursor: pointer;
    }


    .form-lists {
      width: 100%;
      background-color: $gray2;
      height: 100%;
      //margin: 10px;
      .form-lists-container {
        max-width: 1300px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0 auto;
        padding: 30px;
        background-color: $gray2;
        //border: 1px solid $darke-blue;
        border-radius: 5px;
        opacity: 0.9;
        .submit-form-button {
          width: 5%;
          margin-top: 5px;
        }

        .form-list {
          //border: 2px  $gray solid;
          padding: 20px;
          //border-radius: 8px;
          //width: 100%;
          //background-color: white;
          font-size: 17px;
          font-weight: bold;
          display: flex;
          min-height: 25px;
          align-items: center;
          text-decoration: none;
          display: flex;
          justify-content: space-between;
          border-top: 1px solid $gray3;
          color: $darke-gray;
          //border-bottom:1px solid $darke-gray;
          .form-list-details {
            display: flex;

            &:hover {
              color: $principal_blue;
            }

            > a {
              text-decoration: none;
              color: white;
              cursor: pointer;

            }

          }

          &:hover {
            //background-color: $green;
            //color: white;
            //.form-list-icon {
            //  display: block;
            //  margin-right: 20px;
            //  padding: 3px 5px;
            //  border-radius: 10px;
            //  color: $darke-blue;
            //  background-color: $gray;
            //}
            //background-image: url("./src/assets/img/32527.svg");
          }

          > a {
            text-decoration: none;
            color: white;
            cursor: pointer;
          }

          .form-list-details {
            display: flex;
            justify-content: space-between;
          }
        }

        .form-list-actions {
          cursor: pointer;
          color: $darke-blue;
        }

        .form-list-actions > * {
          border-radius: 3px;
          padding: 3px 10px;
          margin: 0px 5px;
          background-color: #d9e2e3;
          color: #30484e;

          &:hover {
            background-color: #b3c5c7;
          }
        }
        .table-header-items {
          font-weight: bold;
        }
      }

      .submit-form-button {
        background-color: $principal_blue;
        color: white;
        font-weight: bold;
      }
    }
  }

}
.form-header {
  display: flex !important;
  justify-content: space-between;
  background-color:#7c7c7c00 !important;
  border-radius: 8px;
  padding: 10px;
  position: relative;
  align-items: center;
  width:100%;
  margin: 0 auto;
  .add-form-text {
    font-size: 30px;
    font-weight: bold;
    color: $darke-gray;
    cursor: pointer;
    text-decoration: none;
  }
  .add-form {
    background-color: $principal_blue;
    color: white;
    padding: 12px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    margin: 10px;
    cursor: pointer;
    justify-content: space-between;
    &:hover {
      background-color: $blue;
    }
  }

}


.add-form-text {
  font-size: 30px;
  font-weight: bold;
  color: $darke-gray;
  cursor: pointer;
  text-decoration: none;
}
