.centered-box {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 24px;
  border-radius: 8px;
  margin: auto;
  box-shadow: 0px 2px 8px rgba(179, 208, 232, 0.6) !important;
  margin-top: 200px;
  width: 100%;
  max-width: 400px;

  & > .title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
  }
  & > .label {
    font-size: 16px;
    font-weight: 500;
    & > a {
      color: $gray;
      text-decoration: none;
      transition: all 300ms ease;
      &:hover {
        color: $darke-gray;
      }
    }
  }
  & > .input {
    border: 2px solid white;
    border-radius: 4px;
    padding: 4px;
    margin: 8px 0;
    outline: none;
    box-sizing: border-box;
    transition: all 300ms ease;
    &:focus {
      border: 2px solid $principal_blue;
    }
  }
  & > .MuiButton-root {
    background: $blue;
    color: white;
    margin: 8px 0;
    padding: 3px;
    &:hover {
      background: $principal_blue;
    }
  }
}
