.question-card {
  display: flex !important;
  min-height: 900px;

  .question-container {
    width: 100%;
    padding: 0px !important;
  }

  .question-header {
    display: flex !important;
    justify-content: flex-end;
    //background: url('../../../assets/img/Taieri.svg'), $principal-blue;
    padding: 10px;
    border-radius: 8px;
    height: 75px;
    position: relative;
    //&:before {
    //  content: '';
    //  background-image: url('../../assets/img/Taieri.svg');
    //  position: absolute;
    //  width: 100%;
    //  height: 100%;
    //  top:0px;
    //  background-size: cover;
    //}

    .add-question-header-button {
      background-color: white;
      color: $principal-blue;
    }

    .question-header-text > div > span {
      font-weight: bold !important;
      color: white;
    }
  }

  .questions-form-title {
    display: flex;
    justify-content: center;
    font-weight: 400;
    color: $darke-blue;
    font-size: larger;
    padding: 20px;
    background-color: $gray2;
    border-radius: 3px;
    font-weight: bold;
    font-size: 25px;
  }
  .questions-form-id {
    display: flex;
    justify-content: center;
    font-weight: 400;
    color:darkblue;
    font-size: larger;
    padding: 20px;
    background-color: $gray2;
    border-radius: 3px;
    border-color: black;
    font-weight: bold;
    font-size: 25px;
  }

  .questions {
    width: 100%;
    background-color: $gray2;
    margin-top: 10px;
    height: 100%;

    questions-form {
      margin: 0 auto;
      max-width: 1190px;
    }
  }

  .questions-cards {
    padding: 0px 25px 25px 25px;
    background-color: $gray2;
    border-radius: 0px 0px 8px 8px;
    margin: 0 auto;
    max-width: 1140px;

    .question {
      border: 2px $gray solid;
      min-height: 100px;
      border-radius: 10px;
      //display: flex;
      flex-direction: column;
      padding: 40px 15px;
      align-items: center;
      margin-bottom: 30px;
      background: white;

      .delete-question {
        position: relative;
        bottom: 60px;
        align-items: flex-end !important;
        background-color:$red;
        color: white;
        padding: 10px;
        border-radius: 50px;
        cursor: pointer;
        float: right;
        &:hover {
          background-color: $darke-red;
        }
      }

      .question-description {
        margin: 20px 20px 0px 20px;
        display: flex;
        justify-content: center;
        border: 1px solid $gray3;
        padding: 20px;
        border-radius: 8px;
        flex-direction: column;

        .description-label {
          margin-bottom: 10px;
        }
      }

      .question-link-video {
        display: flex;
        justify-content: center;
        margin: 15px;
        border: 1px solid #B4BDBE;
        border-radius: 5px;
        max-width: 500px;
        padding: 3px;
        margin: 15px auto;
        .link-icon {
          width: 60px;
          color: $darke-blue;
          font-size: 26px;
        }
          .link-video-input {
            border: none;
            width: 100%;
            font-size: 14px;
            &:focus {
              outline: none !important;
              border: none !important;
            }
        }
        //background-color: #F4F8FF;
      }
      .question-title {
        width: 50%;
        height: 25px;
        border: 1px $darke-gray solid;
        border-radius: 10px;
        padding: 5px;
        margin-right: 45px;
      }

      .question-container-header {
        display: flex;
        width: 100%;
        justify-content: center;

        .question-title-label {
          background-color: $principal-blue;
          color: white;
          padding: 9px;
          margin-right: 20px;
          border-radius: 5px;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
        }
      }

      .question-answers {
        display: flex;
        flex-direction: column;
        max-width: 750px;
        margin: 30px auto;

        .question-answer-header {
          display: flex;
          //justify-content: space-around;
          .type-answer-header {
            margin-right: 15px;
            background: $principal-blue;
            color: white;
            border-radius: 16px;
            padding: 5px 10px;
            font-weight: bold;
            box-shadow: $shadow;
          }

          .value-answer-header {
            margin-left: 15px;
            background: $principal-blue;
            color: white;
            border-radius: 16px;
            padding: 5px 10px;
            font-weight: bold;
            box-shadow: $shadow;
          }
        }

        .answer-container {
          display: flex;
          //justify-content: space-around;
          margin-top: 15px;
          margin-left: 30px;

          .answer-checklist {
            display: flex;
          }

          .answer-text {
            width: 90%;
            margin: 5px 0px 5px 60px;
            border: 1px solid $darke-gray;
            border-radius: 5px;
            padding-left: 20px;
            font-weight: bold;
            @include placeholder-color(black)
          }

          .del-answer-icon {
            margin-left: 10px;
            color: rgb(221, 71, 71);
            background-color: #F0F2F5;
            border-radius: 5px;
            padding: 3px;
            margin-top: 6px;
            cursor: pointer;
            box-shadow: $shadow;
            height: 25px;

          }
        }
      }

      .add-choice-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $green;
        color: white;
        border-radius: 5px;
        padding: 4px;
        cursor: pointer;
        box-shadow: $shadow;
        max-width: 100px;
        margin: 0 auto;
      }
      .question-image {
        display: flex;
        justify-content: center;
        img {
          width: 20%;
          margin:0px 10px;
        }
      }
      .question-audio-player {
        display: flex;
        justify-content: center;
        margin: 20px 0px;
        >div {
          height: 40px !important;
        }
      }
    }
  }

  .submit-questions-button {
    display: flex;
    justify-content: flex-end;
    padding: 20px 20px 0px 10px;
    background-color: $gray2;
    cursor: pointer;
    position: absolute;
    z-index: 10;

    .submit-questions-text {
      background-color: $darke-blue;
      padding: 10px;
      border-radius: 8px;
      color: white;
      font-weight: bold;
      display: flex;
      align-items: center;
      margin:10px;
      &:hover {
        background: $principal-blue
      }

    }

  }
}
.clear-icon {
  margin: 8px 10px;
  cursor: pointer;
}