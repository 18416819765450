.profile-information {
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  max-width: 1140px;
  align-items: center;
  padding: 20px 0px;
  .profile {
    display: flex;
    justify-content: flex-end;
    max-width: 1140px;
    align-items: center;
    cursor: pointer;
    padding: 20px 0px;
    > * {
      color: #5a6e73;
    }

    .profile-email {
      padding: 0px 8px;
      font-weight: bold;
    }
  }


  img {
    cursor: pointer;
  }
}
.profile-card {
  padding: 10px;

  > div {
    display: flex;
    align-items: center;
    cursor: pointer !important;
    margin: 5px;

    &:hover {
      color: $darke-blue;
    }
  }
}