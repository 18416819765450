@mixin placeholder-color($color) {
  &::-webkit-input-placeholder { /* WebKit browsers */
    color: $color;
    font-size: 13px;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color;
    font-size: 13px;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color;
    font-size: 13px;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: $color;
    font-size: 13px;
  }
}
