@import './assets/scss/variables';
@import './assets/scss/mixin';
@import 'Pages/Admin/Forms/form';
@import 'Pages/Student/student_question';
@import 'Pages/Student/student_forms';
@import 'Pages/Login/login';
@import '../src/Components/FileDropeZone/fileDropeZone.js';
@import url('https://fonts.googleapis.com/css2?family=Recursive&display=swap');
@import './Components/navbar/SideNavBar.scss';
.buttons {
  background-color: $principal_blue !important;
  color:white !important;
  
}