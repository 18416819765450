.dropzone {
  display: flex;
  justify-content: center;
  margin: 15px;
  border: 1px solid $gray3;
  border-radius: 5px;
  background-color: $gray-background;
  .dropzone-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .dropzone-icon {
      color: $darke-blue;
      margin-left: 15px;
      font-size: 30px;
    }
  }
}